/**
 * Function for toggle menu
 */
function toggleMenu(){
  $('#main').toggleClass('custom-container-fluid');
  $('#sidebarShow').toggle();
  $('#breadcrumb').toggleClass('pl-md-0');

  if ($(window).width() < 768){
    $('#sidebar').toggleClass('mobile-active');
  } else {
    $('#sidebar').toggleClass('active');
  }
}

/* document ready */
$(function(){
  if ($(window).width() < 768){
    $('#sidebarShow').toggle();
  }
  $('#sidebarHide').on('click', function(){
    toggleMenu();
  });
  $('#sidebarShow').on('click', function(){
    toggleMenu();
  });
});

/* Windows resize */
$( window ).on('resize', function() {
  if ($(window).width() < 768){
    $('#sidebar').removeClass('active');
  } else {
    $('#sidebar').addClass('active');
  }

  if($('#sidebar').hasClass('active')){
    $('#sidebarShow').hide();
  } else {
    $('#sidebarShow').show();
  }
});