window.$ = window.jQuery = require('jquery');

window.Uppy     = {};
Uppy.Core       = require('@uppy/core');
Uppy.Dashboard  = require('@uppy/dashboard');
Uppy.FileInput  = require('@uppy/file-input');
Uppy.XHRUpload  = require('@uppy/xhr-upload');

import './vendors/bootstrap';
import './components/icons.font';
import './components/menu';


import "../../../dw-redmine-tracker/node_modules/@fortawesome/fontawesome-free/js/solid";
import "../../../dw-redmine-tracker/node_modules/@fortawesome/fontawesome-free/js/brands";
import "../../../dw-redmine-tracker/node_modules/@fortawesome/fontawesome-free/js/fontawesome";
